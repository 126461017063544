<template>
  <div id="home" class="home-page">
    <AppHeader />

    <section class="hero-banner">
      <div class="banner-content">
        <div class="text-box">
          <h1 class="banner-title">Phronesis Residences</h1>
          <p class="banner-subtitle">Discover Luxury Living</p>
        </div>
      </div>
    </section>

    <section class="intro-section">
      <div class="section-content intro-content">
        <h2 class="intro-title">Welcome to Phronesis Residences</h2>
        <p class="intro-text">
          Phronesis Residences offers a blend of modern comfort and elegant design, featuring fully furnished 2 and 3 bedroom luxury condos in the heart of Calgary. Whether you're looking for a short or long-term stay, our residences are move-in ready, ensuring that all you need to bring is your personal belongings.
        </p>
        <div class="intro-highlights">
          <div class="highlight">
            <img src="@/assets/icon1.png" alt="Icon 1" class="highlight-icon"/>
            <h3>Prime Location</h3>
            <p>Located near downtown Calgary, with easy access to public transit, dining, and entertainment.</p>
          </div>
          <div class="highlight">
            <img src="@/assets/icon2.png" alt="Icon 2" class="highlight-icon"/>
            <h3>Luxury Interiors</h3>
            <p>Each suite is designed by top interior designers, offering a unique blend of style and comfort.</p>
          </div>
          <div class="highlight">
            <img src="@/assets/icon3.png" alt="Icon 3" class="highlight-icon"/>
            <h3>Community Focused</h3>
            <p>Our professional team is dedicated to making your stay enjoyable and stress-free.</p>
          </div>
        </div>
      </div>
    </section>

    <section id="amenities" class="amenities-section">
      <div class="section-content amenities-content">
        <h2 class="amenities-title">Amenities</h2>

        <div class="amenities-grid">
          <div class="amenity-card">
            <img src="@/assets/concierge.png" alt="Concierge Icon" class="amenity-icon" />
            <h3>Concierge Services</h3>
            <p>Cleaning Service, Meal Delivery, Home Care, Car Service, Child Care</p>
          </div>
          <div class="amenity-card">
            <img src="@/assets/building.png" alt="Building Icon" class="amenity-icon" />
            <h3>Building Features</h3>
            <p>Rooftop Terrace, Rooftop BBQ, Common Spaces, Furnished Units, Heated Garage</p>
          </div>
          <div class="amenity-card">
            <img src="@/assets/community.png" alt="Community Icon" class="amenity-icon" />
            <h3>The Community of Bridgeland</h3>
            <p>Nearby Transit, River Trails, Gyms, Restaurants, Dog Parks</p>
          </div>
          <div class="amenity-card">
            <img src="@/assets/unique_spaces.png" alt="Unique Icon" class="amenity-icon" />
            <h3>Unique Spaces</h3>
            <p>Each condo is unique, fully furnished, spacious, and designed for modern living.</p>
          </div>
        </div>
      </div>
    </section>

    <section class="story-section">
      <div class="section-content story-content">
        <h2 class="story-title">Our Story</h2>
        <div class="story-image-text">
          <img src="@/assets/our_story_image.jpg" alt="Our Story Image" class="story-image"/>
          <div class="story-text-content">
            <p class="story-text">
              Phronesis: A practical wisdom - where you are wise about your intentions, and you have a clear understanding of how to realize them.
            </p>
            <p class="story-text">
              In 2013, Calgary was rocked by floods that brought the community together to overcome the challenge of rebuilding. Those floods impacted the two properties that lived where Phronesis stands today. We decided to honor these homes by building a housing structure that embodied the sense of community Calgarians were searching for. In the summer of 2016, that vision was realized with the completion of Phronesis Residences.
            </p>
            <blockquote class="story-quote">
              "We built Phronesis Residences to be a place where people not only live, but where they truly feel at home, supported by a community."
            </blockquote>
          </div>
        </div>
      </div>
    </section>

    <section id="suites" class="suites-section">
      <div class="section-content suites-content">
        <h2 class="suites-title">Our Suites</h2>
        <p class="suites-text">Phronesis Residences Calgary features 4 fully furnished executive style short and long-term rental suites. Move-in ready accommodations range from 870 - 1400 sq.ft</p>
        <p class="suites-text">Prices start at $2,000/month</p>
        <div class="layouts-grid">
          <div class="layout-card">
            <img src="@/assets/layout1.jpg" alt="Layout 1" class="layout-image"/>
            <h3>Layout 1</h3>
            <p>3 Bedrooms</p>
            <p>3 Bath</p>
            <p>1200 - 1400 sq.ft</p>
          </div>
          <div class="layout-card">
            <img src="@/assets/layout2.jpg" alt="Layout 2" class="layout-image"/>
            <h3>Layout 2</h3>
            <p>3 Bedrooms</p>
            <p>2.5 Bath</p>
            <p>1150 - 1360 sq.ft</p>
          </div>
          <div class="layout-card">
            <img src="@/assets/layout3.jpg" alt="Layout 3" class="layout-image"/>
            <h3>Layout 3</h3>
            <p>3 Bedrooms</p>
            <p>2 Bath</p>
            <p>1050 - 1210 sq.ft</p>
          </div>
          <div class="layout-card">
            <img src="@/assets/layout4.jpg" alt="Layout 4" class="layout-image"/>
            <h3>Layout 4</h3>
            <p>2 Bedrooms</p>
            <p>2 Bath</p>
            <p>870 - 1000 sq.ft</p>
          </div>
        </div>
      </div>
    </section>

    <section id="contact" class="contact-section">
      <div class="section-content contact-content">
        <div class="contact-wrapper">
          <div class="contact-form">
            <RequestQuoteForm />
          </div>
          <div class="contact-info-map">
            <div class="contact-info">
              <h3>Phronesis Residences</h3>
              <p>830 McDougall Rd NE</p>
              <p>Calgary, AB T2E 5A4</p>
              <p>Tel: <a href="tel:+14039789331">403.978.9331</a></p>
              <p>Email: <a href="mailto:info@thephronesis.ca">info@thephronesis.ca</a></p>
              <p>
                Whether you’re interested in booking a suite, have questions about our amenities, or need assistance, our team is here to help. Reach out today, and let's start a conversation about your next home!
              </p>
            </div>
            <div class="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23729.898822183695!2d-114.05722219321172!3d51.056729999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53717e7e8f62ff6b%3A0xf12cfa748e54be16!2s830%20McDougall%20Rd%20NE%2C%20Calgary%2C%20AB%20T2E%205A4%2C%20Canada!5e0!3m2!1sen!2s!4v1620052769455!5m2!1sen!2s"
                width="100%"
                height="100%"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <AppFooter />
  </div>
</template>

<script>
import RequestQuoteForm from '@/components/RequestQuoteForm.vue';
export default {
  name: "HomePage",
  components: {
    RequestQuoteForm,
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.section-content {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero-banner {
  position: relative;
  width: 100%;
  height: 80vh;
  background-image: url('@/assets/banner7.jpg');
  background-size: cover;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.banner-content {
  text-align: center;
  color: white;
}

.text-box {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.banner-title {
  font-size: 48px;
  font-family: 'Exo', sans-serif;
  font-weight: bold;
  color: white;
}

.banner-subtitle {
  font-size: 40px;
  margin-bottom: 10px;
  color: white;
  font-family: "Tangerine", cursive;
  font-weight: 400;
  font-style: normal;
}

.section-divider {
  width: 80%;
  border: 0;
  border-top: 2px solid #ccc;
  margin: 20px 0;
}

.intro-title, .amenities-title, .suites-title, .story-title, .contact-title {
  text-align: center;
}

.intro-section, .amenities-section, .story-section, .suites-section, .contact-section {
  padding: 40px 20px;
  display: flex;
  justify-content: center;
}

.intro-highlights {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  flex-wrap: wrap;
}

.highlight {
  text-align: center;
  max-width: 250px;
  margin-bottom: 20px;
}

.highlight-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 12px;
}

.highlight h3 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.highlight p {
  font-size: 16px;
  color: #555;
}

.amenities-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

@media (min-width: 768px) {
  .amenities-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.amenity-card {
  padding: 0px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  border: 1px solid #e0e0e0;
}

.amenity-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.amenity-icon {
  width: 100%;
  height: 320px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 12px 12px 0 0;
}

.amenity-card h3 {
  font-size: 26px;
  margin-bottom: 10px;
  color: #2c3e50;
}

.amenity-card p {
  font-size: 18px;
  color: #555;
}

.story-image-text {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: nowrap;
}

.story-image {
  width: 50%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.story-text-content {
  width: 50%;
}

.story-text {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;
}

.story-quote {
  font-size: 20px;
  font-style: italic;
  margin-top: 20px;
  color: #2c3e50;
}

@media (max-width: 767px) {
  .story-image-text {
    flex-direction: column;
  }

  .story-image {
    width: 100%;
  }

  .story-text-content {
    width: 100%;
  }
}

.layouts-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .layouts-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.layout-card {
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid #e0e0e0;
}

.layout-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.layout-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.layout-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.layout-card p {
  font-size: 18px;
  color: #555;
  margin-bottom: 5px;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: flex-start;
}
.contact-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .contact-wrapper {
    flex-direction: row;
  }
}

.contact-form, .contact-info-map {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: auto;
}

.contact-info-map {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact-info h3 {
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
}

.contact-info p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.contact-info a {
  color: #1E3A8A;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.map-container {
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
</style>
