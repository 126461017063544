<template>
    <section>
      <h1>Component Title</h1>
      <!-- Добавьте содержимое компонента -->
    </section>
  </template>
  
  <script>
  export default {
    name: 'ComponentName'
  }
  </script>
  
  <style scoped>
  /* Добавьте стили компонента */
  </style>
  