<template>
    <section>
      <h1>Suites</h1>
      <!-- Содержимое Suites -->
    </section>
  </template>
  
  <script>
  export default {
    name: 'SuitesPage'
  }
  </script>
  
  <style scoped>
  /* Стили для Suites */
  </style>
  