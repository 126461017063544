import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue'
import SuitesPage from '../components/SuitesPage.vue'
import AmenitiesPage from '../components/AmenitiesPage.vue'
import GalleryPage from '../components/GalleryPage.vue'
import ContactUsPage from '../components/ContactUsPage.vue'
const routes = [
  { path: '/', component: HomePage },
  { path: '/suites', component: SuitesPage },
  { path: '/amenities', component: AmenitiesPage },
  { path: '/gallery', component: GalleryPage },
  { path: '/contact', component: ContactUsPage }
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
