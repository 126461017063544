<template>
    <div class="gallery-page">
      <h1 class="gallery-title">Gallery</h1>
      <div class="gallery-grid">
        <div v-for="(image, index) in images" :key="index" class="gallery-item" @click="openModal(index)">
          <img :src="image" :alt="'Image ' + (index + 1)" class="gallery-image" />
        </div>
      </div>
  
      <!-- Modal for Image View -->
      <div v-if="showModal" class="modal-overlay" @click="closeModal">
        <div class="modal-content" @click.stop>
          <span class="close-btn" @click="closeModal">&times;</span>
          <img :src="images[currentIndex]" class="modal-image" />
          <button v-if="currentIndex > 0" class="prev-btn" @click="prevImage">❮</button>
          <button v-if="currentIndex < images.length - 1" class="next-btn" @click="nextImage">❯</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "GalleryPage",
    data() {
      return {
        images: [],
        showModal: false,
        currentIndex: 0,
      };
    },
    created() {
      this.loadImages();
    },
    methods: {
      loadImages() {
        // Load all images from assets directory
        for (let i = 1; i <= 39; i++) {
          try {
            this.images.push(require(`@/assets/gallery/image (${i}).webp`));
          } catch (error) {
            console.warn(`Image ${i} not found`);
          }
        }
      },
      openModal(index) {
        this.currentIndex = index;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      prevImage() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        }
      },
      nextImage() {
        if (this.currentIndex < this.images.length - 1) {
          this.currentIndex++;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .gallery-page {
    /* padding: 40px 20px; */
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 80px; /* Ensure there's enough space for the title to be visible */
    text-align: center;
  }
 
  
  .gallery-title {
    text-align: center;     
    color: #333;
    margin-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    aspect-ratio: 1 / 1; /* Maintain a square container */
  }
  
  .gallery-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain; /* Ensure the entire image fits within the container */
    transition: transform 0.3s ease;
  }
  
  .gallery-item:hover .gallery-image {
    transform: scale(1.05);
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  
  .prev-btn,
  .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 30px;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  
  .prev-btn:hover,
  .next-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  </style>
  