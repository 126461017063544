<template>
  <header class="app-header">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/logo2.png" alt="Logo" />
      </router-link>
    </div>
    <nav class="nav" :class="{ open: isMenuOpen }">
      <template v-if="$route.path === '/gallery'">
        <router-link to="/" @click="navigateAndScroll('home')">Home</router-link>
        <router-link to="/" @click="navigateAndScroll('suites')">Suites</router-link>
        <router-link to="/" @click="navigateAndScroll('amenities')">Amenities</router-link>
        <router-link to="/gallery">Gallery</router-link>
        <router-link to="/" @click="navigateAndScroll('contact')">Contact Us</router-link>
      </template>
      <template v-else>
        <a href="#home" @click="closeMenu">Home</a>
        <a href="#suites" @click="closeMenu">Suites</a>
        <a href="#amenities" @click="closeMenu">Amenities</a>
        <router-link to="/gallery" @click="closeMenu">Gallery</router-link>
        <a href="#contact" @click="closeMenu">Contact Us</a>
      </template>
    </nav>
    <div class="burger-menu" @click="toggleMenu">
      <div class="line" :class="{ open: isMenuOpen }"></div>
      <div class="line" :class="{ open: isMenuOpen }"></div>
      <div class="line" :class="{ open: isMenuOpen }"></div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    navigateAndScroll(sectionId) {
      this.$router.push('/').then(() => {
        this.scrollToSection(sectionId);
      });
    },
    scrollToSection(sectionId) {
      this.$nextTick(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    }
  },
};
</script>

<style scoped>
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: white;
  border-bottom: 2px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #4a4a4a;
}

.logo img {
  max-width: 120px;
  height: auto;
  display: block;
}

.nav {
  display: flex;
  gap: 15px;
  margin-left: auto;
}

.nav a {
  text-decoration: none;
  color: #4a4a4a;
  font-weight: 600;
  white-space: nowrap;
}

.nav a:hover {
  color: #1e3a8a;
}

/* Стили для бургер-меню */
.burger-menu {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.burger-menu .line {
  width: 25px;
  height: 3px;
  background-color: #4a4a4a;
  transition: all 0.3s ease;
}

.burger-menu .line.open:nth-child(1) {
  transform: rotate(45deg) translateY(8px);
}

.burger-menu .line.open:nth-child(2) {
  opacity: 0;
}

.burger-menu .line.open:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px);
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 768px) {
  .nav {
    position: fixed;
    top: 70px;
    right: 0;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
    border-left: 2px solid #ddd;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 999;
  }

  .nav.open {
    transform: translateX(0);
  }

  .burger-menu {
    display: flex;
  }
}

/* Дополнительные стили для страницы */
html, body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  padding-top: 70px;
}
</style>
