<template>
    <footer>
      <p>© 2024 Phronesis Residences. All rights reserved.</p>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'AppFooterPage'
  }
  </script>
  
  <style scoped>
  footer {
    text-align: center;
    padding: 20px;
    background-color: #f3f4f6;
    color: #4b5563;
  }
  </style>
  