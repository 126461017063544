<template>
    <div id="request-quote" class="cta-section">
      <h2 v-if="!formSubmitted">Interested in Our Residences?</h2>
      <p v-if="!formSubmitted">Reach out to us to learn more about our luxurious suites or to schedule a visit.</p>
  
      <!-- Форма для отправки -->
      <form v-if="!formSubmitted" @submit.prevent="submitForm">
        <input type="text" placeholder="Your Name" v-model="form.name" required />
        <input type="email" placeholder="Your Email" v-model="form.email" required />
        <input type="tel" placeholder="Your Phone Number" v-model="form.phone" required />
        <textarea placeholder="Message" v-model="form.details" required></textarea>
        <button type="submit" :disabled="loading">
          <!-- Показываем иконку загрузки, если идет отправка данных -->
          <span v-if="loading" class="loading-spinner"></span>
          <span v-else>Contact Us</span>
        </button>
      </form>
  
      <!-- Сообщение с благодарностью -->
      <div v-else class="thank-you-message">
        <h2>Thank You!</h2>
        <p>We have received your request and will get back to you shortly.</p>
        <p>If you have any further questions, feel free to reach out to us at any time.</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: "RequestQuoteForm",
    data() {
      return {
        form: {
          name: '',
          email: '',
          phone: '',  // Добавлено поле для номера телефона
          details: ''
        },
        formSubmitted: false,
        loading: false,
      };
    },
    methods: {
      async submitForm() {
        this.loading = true;
        try {
          const response = await axios.post('https://printmywall.ca/api/Email/send', {
            name: this.form.name,
            email: this.form.email,
            phone: this.form.phone,
            details: this.form.details
          });
  
          if (response.status === 200) {
            this.formSubmitted = true;
          } else {
            alert('There was an error submitting your request.');
          }
        } catch (error) {
          console.error('Error submitting the form:', error);
          alert('An error occurred while submitting your request. Please try again later.');
        } finally {
          this.loading = false;
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .cta-section {
    padding: 40px 20px;
    background-color: #f8f8f8;
    text-align: center;
    margin-top: 40px;
  }
  
  .cta-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .cta-section p {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  
  .cta-section form {
    display: grid;
    gap: 15px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .cta-section input,
  .cta-section textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .cta-section button {
    padding: 12px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #F37C1F;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
  }
  
  .cta-section button[disabled] {
    cursor: not-allowed;
    background-color: #ccc;
  }
  
  .cta-section button:hover:not([disabled]) {
    background-color: #e66900;
  }
  
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .thank-you-message {
    text-align: center;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 8px;
  }
  
  .thank-you-message h2 {
    color: #4caf50;
    font-size: 2rem;
    margin-bottom: 15px;
  }
  
  .thank-you-message p {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  </style>
  